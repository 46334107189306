<form
  nz-form
  [formGroup]="passwordForm"
  [nzLayout]="'vertical'"
  (ngSubmit)="onSubmit()"
>
  <div nz-row [nzGutter]="[24, 24]">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label nzRequired>Current Password</nz-form-label>
        <nz-form-control nzErrorTip="Please input your password!">
          <input
            [nzSize]="'large'"
            nz-input
            type="password"
            placeholder="Type your current password"
            formControlName="currentPassword"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row [nzGutter]="[24, 24]">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label nzRequired>New Password</nz-form-label>
        <nz-form-control nzHasFeedback [nzErrorTip]="newPassTpl">
          <input
            [nzSize]="'large'"
            nz-input
            type="password"
            placeholder="Type new password"
            formControlName="newPassword"
            (ngModelChange)="validateConfirmPassword()"
          />
          <ng-template #newPassTpl let-control>
            <ng-container *ngIf="control.hasError('required')"
              >Please input your password. </ng-container
            >
            <ng-container *ngIf="control.hasError('invalidPassword')">
              {{ control.errors.message }}
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row [nzGutter]="[24, 24]">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label nzRequired>Confirm Password</nz-form-label>
        <nz-form-control nzHasFeedback [nzErrorTip]="passwordErrorTpl">
          <input
            [nzSize]="'large'"
            nz-input
            type="password"
            formControlName="confirmPassword"
            placeholder="Confirm your password"
          />
          <ng-template #passwordErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')"
              >Please confirm your password!</ng-container
            >
            <ng-container *ngIf="control.hasError('match')"
              >Password is inconsistent!</ng-container
            >
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row [nzGutter]="[24, 24]">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-control>
          <button
            nz-button
            nzType="primary"
            [nzSize]="'large'"
            [disabled]="!passwordForm.valid"
            type="submit"
            [nzLoading]="loading"
          >
            Change Password
          </button>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>
