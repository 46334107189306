import { NewUser, User, UserParams } from "./users.model";

export class GetUsers {
  static readonly type = '[Users] Get';
  constructor(public readonly payload: UserParams) {}
}

export class GetUser {
  static readonly type = '[Users] Detail';
  constructor(public readonly payload: string) {}
}

export class GetUserRoles {
  static readonly type = '[Users] Roles';
  constructor(public readonly payload: string) {}
}

export class UpdateUserInfo {
  static readonly type = '[Users] Update';
  constructor(public readonly payload: User) {}
}

export class AddUser {
  static readonly type = '[Users] Add';
  constructor(public readonly payload: NewUser) {}
}

export class DeleteUser {
  static readonly type = '[Users] Delete';
  constructor(public readonly payload: string) {}
}

export class GetAssignableRoles {
  static readonly type = '[Users] All Roles';
}