import {
  HttpErrorResponse,
  HttpEvent, HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {
  constructor(private notification: NzNotificationService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        let errData: { code: string | number, message: string } = { code: '', message: ''};
        if (err.error instanceof ErrorEvent) {
          // Client side
          errData.code = err.status;
          errData.message = err.message;
        } else {
          // Server side
          errData.code = !!err.error ? err.error.code : err.status;
          errData.message = err.error.error_description ?? err.error.error.message;
        }
        
        this.notification.create(
          'error',
          `Error`,
          `${errData.message}`,
          { nzDuration: 4000 }
        );
        return throwError(() => err);
      })
    );
  }
}
