import { Injectable } from '@angular/core';
import {
  Action,
  Actions,
  ofAction,
  Selector,
  State,
  StateContext
} from '@ngxs/store';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { catchError, takeUntil, tap, throwError } from 'rxjs';
import { UserService } from 'src/app/core/services/user.service';
import {
  AddUser,
  DeleteUser,
  GetAssignableRoles,
  GetUser,
  GetUserRoles,
  GetUsers,
  UpdateUserInfo
} from './users.actions';
import { Roles, User, UsersStateModel } from './users.model';

@State<UsersStateModel>({
  name: 'users',
  defaults: {
    users: [],
    total: 0,
    loading: false,
    selected: null,
    selectedRoles: null,
    assignableRoles: null,
  },
})
@Injectable()
export class UsersState {
  @Selector()
  static getUsers(state: UsersStateModel) {
    return state.users ?? [];
  }

  @Selector()
  static getUser(state: UsersStateModel) {
    return state.selected ?? null;
  }

  @Selector()
  static getUserRoles(state: UsersStateModel) {
    return state.selectedRoles ?? null;
  }

  @Selector()
  static getAssignableRoles(state: UsersStateModel) {
    return state.assignableRoles ?? null;
  }

  @Selector()
  static loading(state: UsersStateModel) {
    return state.loading;
  }

  constructor(
    private actions: Actions,
    private userService: UserService,
    private notification: NzNotificationService
  ) {}

  @Action(GetUsers, { cancelUncompleted: true })
  getUsers(store: StateContext<UsersStateModel>, action: GetUsers) {
    store.patchState({ loading: true, users: [] });

    return this.userService.getUsers(action.payload).pipe(
      tap((users: any) => {
        store.patchState({
          users: users.items,
          total: users.totalCount,
          loading: false,
          selected: null,
        });
      }),
      catchError((error: any) => {
        store.patchState({
          users: [],
          total: 0,
          loading: false,
          selected: null,
        });
        return throwError(() => error);
      })
    );
  }

  @Action(GetUser, { cancelUncompleted: true })
  getUser(store: StateContext<UsersStateModel>, action: GetUser) {
    store.patchState({ loading: true, selected: null });

    return this.userService.getUser(action.payload).pipe(
      tap((user: User) => {
        store.patchState({
          loading: false,
          selected: user,
        });
      }),
      takeUntil(this.actions.pipe(ofAction(GetUsers))),
      catchError((error: any) => {
        store.patchState({
          loading: false,
          selected: null,
        });
        return throwError(() => error);
      })
    );
  }

  @Action(GetUserRoles, { cancelUncompleted: true })
  getUserRoles(store: StateContext<UsersStateModel>, action: GetUserRoles) {
    store.patchState({ loading: true, selectedRoles: null });

    return this.userService.getUserRoles(action.payload).pipe(
      tap((roles: Roles) => {
        store.patchState({
          loading: false,
          selectedRoles: roles.items,
        });
      }),
      catchError((error: any) => {
        store.patchState({
          loading: false,
          selectedRoles: null,
        });
        return throwError(() => error);
      })
    );
  }

  @Action(AddUser, { cancelUncompleted: true })
  addUser(store: StateContext<UsersStateModel>, action: AddUser) {
    store.patchState({ loading: true });

    return this.userService.addUser(action.payload).pipe(
      tap((user: User) => {
        store.patchState({
          loading: false,
        });
        this.notification.create(
          'success',
          `User created`,
          `User addedd successfully`,
          { nzDuration: 4000 }
        );
      }),
      catchError((error: any) => {
        store.patchState({
          loading: false,
        });
        return throwError(() => error);
      })
    );
  }

  @Action(UpdateUserInfo, { cancelUncompleted: true })
  updateUser(store: StateContext<UsersStateModel>, action: UpdateUserInfo) {
    store.patchState({ loading: true, selectedRoles: null });

    return this.userService.updateUser(action.payload).pipe(
      tap((user: User) => {
        store.patchState({
          loading: false,
          selected: user,
        });
        this.notification.create(
          'success',
          `Success`,
          `User ${user.userName} updated successfully`,
          { nzDuration: 4000 }
        );
      }),
      catchError((error: any) => {
        store.patchState({
          loading: false,
        });
        return throwError(() => error);
      })
    );
  }

  @Action(DeleteUser, { cancelUncompleted: true })
  deleteUser(store: StateContext<UsersStateModel>, action: DeleteUser) {
    store.patchState({ loading: true });

    return this.userService.deleteUser(action.payload).pipe(
      tap((user: User) => {
        store.patchState({
          loading: false,
          selected: null,
        });
        this.notification.create(
          'success',
          `Success`,
          `User deleted successfully`,
          { nzDuration: 4000 }
        );
      }),
      catchError((error: any) => {
        store.patchState({
          loading: false,
        });
        return throwError(() => error);
      })
    );
  }

  @Action(GetAssignableRoles, { cancelUncompleted: true })
  getAssignableRoles(store: StateContext<UsersStateModel>) {
    store.patchState({ loading: true, assignableRoles: null });

    return this.userService.getAssignableRoles().pipe(
      tap((roles: Roles) => {
        store.patchState({
          loading: false,
          assignableRoles: roles.items,
        });
      }),
      catchError((error: any) => {
        store.patchState({
          loading: false,
          assignableRoles: null,
        });
        return throwError(() => error);
      })
    );
  }
}
