import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { SessionStorageService } from 'src/app/auth/services/session-storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  username: string | null = '';

  isVisible: boolean = false;
  loading: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private storageService: SessionStorageService,
    private notification: NzNotificationService
  ) {}

  ngOnInit(): void {
    this.username = this.storageService.getUser();
  }

  logout() {
    this.authService.signOut();
    this.router.navigate(['auth/login']);
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  changePassword(evt: any) {
    if (evt) {
      this.loading = true;
      this.authService
        .changePassword(evt)
        .pipe(take(1))
        .subscribe(
          (res: any) => {
            this.loading = false;
            if (res.status === 204 || res.status === 200) {
              this.handleCancel();
              this.notification.create(
                'success',
                'Password change',
                'Password changed successfully',
                { nzDuration: 4000 }
              )
            }
          },
          (err) => {
            this.loading = false;
          }
        );
    }
  }
}
