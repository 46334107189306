import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthResponse, NewPassword } from '../interfaces/auth-reponse';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private storageService: SessionStorageService
  ) {}

  signIn(user: { username: string; password: string }): Observable<AuthResponse> {
    const data = { ...environment.authConfig, ...user };
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
    const params = new HttpParams({
      fromObject: data
    })
    return this.http
      .post<AuthResponse>(`${environment.urlConfig.authUrl}/connect/token`, params, {
        headers,
      })
      .pipe(
        tap((response: AuthResponse) => {
          this.store(response, data.username);
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  store(data: AuthResponse, user: string) {
    this.storageService.storeData(data, user);
  }

  signOut() {
    if (this.isAuthenticated()) {
      this.storageService.cleanStorage();
    }
  }

  isAuthenticated(): boolean {
    const token = this.storageService.getToken();
    return !!token;
  }

  changePassword(data: NewPassword) {
    return this.http.post<NewPassword>(`${environment.urlConfig.apiUrl}/account/my-profile/change-password`, data, { observe: 'response'});
  }
}
