import { Injectable } from '@angular/core';
import { AuthResponse } from '../interfaces/auth-reponse';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor() { }

  cleanStorage(): void {
    sessionStorage.clear();
  }

  storeData(data: AuthResponse, user: string): void {
    Object.keys(data).forEach(element => {
      const val = data[element as keyof AuthResponse];
      sessionStorage.setItem(element, val.toString());
    });
    sessionStorage.setItem('username', user);
  }

  getToken(): string | null {
    const tokenInfo = sessionStorage.getItem('access_token');
    return tokenInfo ? tokenInfo : null;
  }

  getTokenType(): string | null {
    const tokenType = sessionStorage.getItem('token_type');
    return tokenType ? tokenType : null;
  }

  getUser(): string | null {
    const userInfo = sessionStorage.getItem('username');
    return userInfo ? userInfo : null;
  }
}
