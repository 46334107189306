import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { catchError, Observable, tap, throwError } from 'rxjs';
import {
  NewUser,
  Role,
  Roles,
  User,
  Users
} from 'src/app/store/users/users.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private http: HttpClient,
    private notification: NzNotificationService,
    private router: Router,
    private zone: NgZone
  ) {}

  getUsers(query: any): Observable<Users> {
    const params = new HttpParams({
      fromObject: query
    })
    return this.http
      .get<Users>(`${environment.urlConfig.apiUrl}/identity/users`, { params })
      .pipe(
        tap((users: Users) => {
          users.items = users.items.filter(
            (user: User) => user.userName !== 'admin'
          );
        })
      );
  }

  getUser(id: string): Observable<User> {
    return this.http
      .get<User>(`${environment.urlConfig.apiUrl}/identity/users/${id}`)
      .pipe(
        tap((user: User) => {
          if (user.name === 'admin') {
            throw new HttpErrorResponse({
              status: 404,
              statusText: 'User not found',
              error: {},
            });
          }
        }),
        catchError((err: HttpErrorResponse) => {
          this.notification.create('error', `Error`, `${err.statusText}`, {
            nzDuration: 4000,
          });
          this.zone.run(() => this.router.navigate(['users']));
          return throwError(() => err);
        })
      );
  }

  getUserRoles(id: string): Observable<Roles> {
    return this.http.get<Roles>(
      `${environment.urlConfig.apiUrl}/identity/users/${id}/roles`
    );
  }

  getAssignableRoles(): Observable<Roles> {
    return this.http
      .get<Roles>(
        `${environment.urlConfig.apiUrl}/identity/users/assignable-roles`
      )
      .pipe(
        tap((roles: Roles) => {
          roles.items = roles.items.filter(
            (role: Role) => role.name !== 'admin'
          );
        })
      );
  }

  addUser(user: NewUser): Observable<User> {
    return this.http.post<User>(
      `${environment.urlConfig.apiUrl}/app/user/user`,
      user
    );
  }

  updateUser(user: User): Observable<User> {
    return this.http.put<User>(
      `${environment.urlConfig.apiUrl}/identity/users/${user.id}`,
      user
    );
  }

  deleteUser(id: string): Observable<User> {
    return this.http.delete<User>(
      `${environment.urlConfig.apiUrl}/identity/users/${id}`
    );
  }
}
