import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  errCodes = [401, 403];

  constructor(
    private router: Router,
    private authService: AuthService,
    private zone: NgZone
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err instanceof HttpErrorResponse) {
          if (this.errCodes.some((code: number) => code === err.status)) {
            this.authService.signOut();
            this.zone.run(() => this.router.navigate(['auth/login']));
          }
        }
        return throwError(() => err);
      })
    );
  }
}
