<nz-layout class="layout-container">
  <nz-sider nzWidth="265px" nzTheme="dark" nzCollapsible nzBreakpoint="md" [nzCollapsedWidth]="0">
    <app-navigation></app-navigation>
  </nz-sider>
  <nz-layout class="inner-layout">
    <nz-header>
      <app-header></app-header>
    </nz-header>
    <nz-content>
      <router-outlet></router-outlet>
    </nz-content>
  </nz-layout>
</nz-layout>
