import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { NewPassword } from 'src/app/auth/interfaces/auth-reponse';
import { passwordValidator } from '../../validators/password.validator';

@Component({
  selector: 'app-password-form',
  templateUrl: './password-form.component.html',
  styleUrls: ['./password-form.component.scss'],
})
export class PasswordFormComponent implements OnInit {
  passwordForm!: FormGroup;

  @Input() loading: boolean = false;

  @Output() formValues = new EventEmitter<any>();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.passwordForm = this.fb.group({
      currentPassword: [null, [Validators.required]],
      newPassword: [null, [Validators.required, passwordValidator]],
      confirmPassword: [null, [Validators.required, this.passwordMatch]],
    });
  }

  passwordMatch = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value !== this.passwordForm.get('newPassword')?.value) {
      return { match: true, error: true };
    }
    return {};
  };

  validateConfirmPassword(): void {
    setTimeout(() =>
      this.passwordForm.get('confirmPassword')?.updateValueAndValidity()
    );
  }

  onSubmit() {
    if (this.passwordForm.valid) {
      const values: NewPassword = (({ currentPassword, newPassword }) => ({
        currentPassword,
        newPassword,
      }))(this.passwordForm.value);
      this.formValues.emit(values);
    }
  }
}
