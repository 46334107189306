<div class="header-container">
  <div></div>
  <div class="user-menu">
    <div class="usr-container">
      <span>Welcome</span>
      <a nz-dropdown [nzDropdownMenu]="usrMenu">
         <p>{{ username }}</p>
        <i nz-icon nzType="down"></i>
      </a>
      <nz-dropdown-menu #usrMenu="nzDropdownMenu">
        <ul nz-menu nzSelectable style="min-width: 200px;">
          <li nz-menu-item (click)="showModal()" class="p-1">
            <i nz-icon nzType="lock"></i
            ><span class="pl-1">Change password</span>
          </li>
          <li nz-menu-item (click)="logout()" nzDanger class="p-1">
            <i nz-icon nzType="logout"></i> <span class="pl-1">Logout</span>
          </li>
        </ul>
      </nz-dropdown-menu>
    </div>
  </div>
</div>
<nz-modal
  [(nzVisible)]="isVisible"
  nzTitle="Change password"
  [nzFooter]="null"
  (nzOnCancel)="handleCancel()"
>
  <ng-container *nzModalContent>
    <app-password-form [loading]="loading" (formValues)="changePassword($event)"></app-password-form
  ></ng-container>
</nz-modal>
