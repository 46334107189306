export const environment = {
  production: false,
  appName:'Kyber Admin',
  appVersion: require('../../package.json').version + '-dev',
  authConfig: {
    client_id: 'KyberSpreadsheet_Admin', // KyberSpreadsheet_Admin
    grant_type: 'password',
    scope: 'KyberSpreadsheetUsers offline_access', // KyberSpreadsheetUsers
  },
  urlConfig: {
    authUrl: 'https://identityapi.dev.optimize.kyber.ai',
    apiUrl: 'https://usersapi.dev.optimize.kyber.ai/api',
  },
};
