<div class="sider-menu">
  <div class="logo">
    <img src="assets/logo.svg" alt="Kyber Logo" srcset="" />
    <nz-divider></nz-divider>
  </div>

  <ul nz-menu nzMode="inline" nzTheme="dark">
    <ul>
      <li nz-menu-group nzTitle="Menu">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a [routerLink]="['/', 'users']">
              <i nz-icon nzType="team" nzTheme="outline"></i>
              Users
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </ul>
</div>
